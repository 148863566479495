import { AppDataServices } from '@store/entity/entity-services';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from '@common/global/app.global.service';
import { Constants } from '@common/global/app.global.constants';
import { UserLog } from '@common/models/userLog';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { Milestone } from '@models/milestone';
import { MilestoneCreateComponent } from '../milestone-create/milestone-create.component';
import * as _ from 'lodash';
import { Category } from '@models/category';
import { Goal } from '@models/goal';

@Component({
  selector: 'app-goals-create',
  templateUrl: './goals-create.component.html',
  styleUrls: ['./goals-create.component.scss']
})
export class GoalsCreateComponent implements OnInit {
  public fromReductionSuggestion: number | null = null;

  public modalType: 'create' | 'update';
  public modalData: Partial<Goal & { goalType: string }>;
  public modalCategories: Category[];

  ButtonText = 'Save Goal';
  userLogs: UserLog = new UserLog();
  goalInfo: any = {
    "goalTypeId": 0,
    "scheduledDate":null,
    "targetDate": null,
    "enterpriseId": this.globals.currentEnterpriseId,
    "createdUserId": null,
    "modifiedUserId": null,
    "goalMembers": [],
    "goalTeams": [],
    "goalTitle": "",
    "description": "",
    "completionDate": null,
    "remarks": "",
    "goalLevelId": null,
    "goalCategoryId": 0,
    "taskStateId": null,
    "status":"new"
  }
  newMilestone:any = {
        "milestone": "",
        "targetDate": null,
        "completionDate": null,
        "description": "",
        "status": "new",
        "enterpriseId": this.globals.currentEnterpriseId
  }


  roles = Constants.roles;
  goalMilestones:Milestone[] = [];
  diable_update_button = false;
  notSaved = false;
  isSaving = false;
  hasTeamManagerRole = false;
  hasEnterpriseRole = false;
  users:any[] = [];
  teams:any[] = [];
  selectedUsers: any[] = [];
  selectedTeams: any[] = [];
  milestoneModalRef: BsModalRef;
  
  public eventT: EventEmitter<any> = new EventEmitter();
  constructor(public datepipe: DatePipe,public bsModalRef: BsModalRef,private modalService: BsModalService, public globals: GlobalService, private appDataServices: AppDataServices) {
    this.hasTeamManagerRole = !!this.globals.getRole(this.roles.TEAM);
    this.hasEnterpriseRole = !!this.globals.getRole(this.roles.ENTERPRISE);    
  }

  ngOnInit() {
    if (this.modalType == 'create') {
      this.goalInfo = {
        "goalTypeId": 0,
        "goalTitle": "",
        "description": "",
        "scheduledDate":null,
        "targetDate":null,
        "completionDate": null,
        "goalCategoryId": 0,
        "taskStateId": null,
        "status": 'new',
        "createdUserId" : this.globals.currentUserId,
        "modifiedUserId" : this.globals.currentUserId,
        "goalMembers" : [+this.globals.currentUserId],
        "goalTeams": [],
        "goalMilestones": [],
        "enterpriseId" : this.globals.currentEnterpriseId,
        ...this.modalData,
      };

      this.ButtonText = 'Save Goal';

    } else {
      this.goalInfo = {
        ...this.modalData,
        "targetDate": (this.modalData.targetDate) ?  new Date(this.modalData.targetDate) : null,
        "scheduledDate": (this.modalData.scheduledDate) ? new Date(this.modalData.scheduledDate) : null,
        "completionDate": (this.modalData.completionDate) ? new Date(this.modalData.completionDate) : null,
        "goalCategoryId": +this.modalData.goalCategoryId || 0,
        "createdUserId" : this.modalData.createdUserId || this.globals.currentUserId,
        "modifiedUserId" : this.modalData.modifiedUserId || this.globals.currentUserId,
        "goalMembers" : this.modalData.goalMembers || [+this.globals.currentUserId],
        "goalTeams" : this.modalData.goalTeams || [],
        "enterpriseId" : this.modalData.enterpriseId || this.globals.currentEnterpriseId,
        "taskStateId":  this.modalData.taskStateId || 0,
        "goalMilestones": this.modalData.goalMilestones || []
      };

      if((this.modalData.createdUserId!=this.globals.currentUserId) && !this.hasEnterpriseRole && !this.hasTeamManagerRole){
        this.diable_update_button = true;
      }
      this.ButtonText = 'Update Goal';
    }

     this.goalMilestones = _.cloneDeep(this.modalData.goalMilestones||[]);
     this.getLists();
  }

  
  getLists() {
    combineLatest([
      this.appDataServices.userService.entities$,
      this.appDataServices.teamService.entities$,
      this.appDataServices.commonService.goalLevels$,
      this.appDataServices.commonService.goalTypes$
    ])
    .subscribe(([users, teams, levels, types]) => { 
      this.users = users || [];
      this.teams = teams || [];
      this.selectedTeams = [];
      this.selectedUsers = [];
      
      if(this.goalInfo.goalTeams){
        let teamIds = (this.goalInfo.goalTeams||[]).map(item=> item.id);
        this.selectedTeams = this.teams.filter(team=>teamIds.includes(team.id));      
      }
      if(this.goalInfo.goalMembers){
        let userIds = (this.goalInfo.goalMembers||[]).map(item=> item.userId);
        this.selectedUsers = this.users.filter(user=>userIds.includes(user.id));      
      }
      
      if(levels) {
        let LevelCompareData =  (this.goalInfo.goalType || 'individual').toLowerCase();
        for (let i = 0; i < levels.length; i++) {
          if (levels[i].levelName.toLowerCase() == LevelCompareData) {
            this.goalInfo.goalLevelId = levels[i].id;
          }
        }
      }
      if(types) {
        let LevelCompareData = (this.goalInfo.goalType=='individual') ? 'Personal' : 'Professional';
        for(let i=0;i<this.modalCategories.length;i++){
            if(this.modalCategories[i].name == "Miscellaneous" && (this.modalType == 'create')){
              this.goalInfo.goalCategoryId = +this.modalCategories[i].id;
            }
          }
        
        for (let i = 0; i < types.length; i++) {
          if (types[i].typeName == LevelCompareData) {
            if(this.modalType == 'create'){
              this.goalInfo.goalTypeId = types[i].id;
            }
          }
        }
      }
    });
  }

  
  get dateFormat() {
    return this.globals.dateFormat;
  }

  get dateFormat2() {
    return this.globals.dateFormat.toLowerCase().replace(/m/g,'M');
  }

  selectedTeamsChange(event) {
    this.notSaved=true;
    let members:any[] = [];
    let userIds = this.users.map(item=>item.id);
    let selectedUsers = this.selectedUsers.map(item=>item.id);
    this.selectedTeams.forEach(team=>{
      (team.teamMembers||[]).forEach(member => {
        if(!selectedUsers.includes(member.id)&& userIds.includes(member.id))
          members.push(member);
      });
    })
    this.selectedUsers = [...this.selectedUsers, ...members];
  }

  addMilestone(){
    let newMilestone = {...this.newMilestone,
      enterpriseId: this.globals.currentEnterpriseId,
      goalId:(this.goalInfo.id || 0)
    };
    this.goalMilestones = [...this.goalMilestones, newMilestone];
  }

  tooltip(label,emptyLabel, value){
    return `${value ? label: emptyLabel}`+ (value ? `: ${value}`:'');
  }

  close(){
    this.bsModalRef.hide();
  }

  closeModal(f: NgForm){
    if(this.notSaved) {
      this.confirmCloseSave(f);
    } else {
      this.close();
    }    
  }

  confirmCloseSave(f: NgForm) {
    this.globals.showConfirm('Are you sure you want to leave without saving your changes?', true, true, 'Save Changes', 'Discard Changes', 'confirm-success', 'confirm-danger')
    .subscribe((result) => {
      if (result) {
        if(f.form.valid)
          this.saveGoals();
      }
      else
       this.close();
    });
  }

  saveGoals() {
    let userIds = (this.selectedUsers ||[]).map(user=>user.id);
    if(!userIds.includes(this.globals.currentUserId))
      userIds.push(this.globals.currentUserId);

    let savingGoalInfo={
      ...this.goalInfo,
      goalTeams : this.selectedTeams.map(team=>team.id),
      goalMembers: userIds,
      remarks: this.goalInfo.remarks || "",
      status: this.goalInfo.status || 'new',
      scheduledDate: this.goalInfo.scheduledDate || this.goalInfo.targetDate,
      completionDate: this.goalInfo.status=='completed' ? (this.goalInfo.completionDate || new Date()) : null,
      goalMilestones: (this.goalMilestones||[]).filter(item=>item.milestone!=null)
    };

    this.globals.showLoading();
    this.isSaving = true;
    if (this.modalType == 'create') {
      const obs = this.fromReductionSuggestion !== null
          ? this.appDataServices.goalService.addFromSuggestion({
            ...(Goal.toRequest(savingGoalInfo)),
            reductionSuggestionId: this.fromReductionSuggestion
          })
          : this.appDataServices.goalService.add(savingGoalInfo);

      obs.subscribe(
          () => {
            this.isSaving = false;
            this.globals.hideLoading();
            this.globals.showSuccessMessage('Goal created successfully');
            this.appDataServices.milestoneService.load().subscribe();
            this.eventT.emit('true');
            this.bsModalRef.hide();
          }, () => {
            this.isSaving = false;
            this.globals.hideLoading();
          });
    } else {
      this.appDataServices.goalService.update(savingGoalInfo).subscribe(
        () => {
          this.isSaving = false;
          this.globals.hideLoading();
          this.globals.showSuccessMessage("Goal updated successfully");
          this.appDataServices.milestoneService.load().subscribe();
          this.eventT.emit('true');
          this.bsModalRef.hide();
        }, () => {
          this.isSaving = false;
          this.globals.hideLoading();
        });

    }

  }
  
  openMilestoneModal(type,data) {
    const initialState = {
      modalType: type,
      modalData: {...data, createdUserId:this.globals.currentUserId}
    };
    this.milestoneModalRef = this.modalService.show(MilestoneCreateComponent, { 
      initialState,
      class: 'gray modal-lg',
      backdrop: 'static'
     });
  }

  toggleMilestoneStatus(item){
    (item||{}).status= item.status=='completed' ? 'new': 'completed';    
    (item||{}).completionDate= item.status=='completed' ? null: new Date();
    this.notSaved=true;
  }

  deletemilestone(index) {
    this.globals.showConfirm('Are you sure you want to delete this milestone? ', true, true, 'Delete', 'Cancel', 'confirm-danger', 'confirm-success')
    .subscribe((result) => {
     if (result) {
        (this.goalMilestones||[]).splice(index, 1);
      }      
   });
   this.notSaved=true;
 }

}
