<form novalidate #f="ngForm" (ngSubmit)="f.form.valid && saveMilestone()" >
    <div class="modal-header">
        <h4 class="w-100 text-center title"> {{modalType == 'create' ? 'New' : 'Update'}} milestone</h4>
        <div class="d-flex flex-row ml-auto justify-content-end gap-1">
            <button type="button" class="close text-muted ml-auto pl-0" aria-label="Close" (click)="closeModal(f)">
                <span aria-hidden="true" >&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body modal-lg pb-0 pt-4" style=" max-width: 100% !important; margin-right: 0;">
        
        <div class="form-group">
            <label for="title">Milestone title<span style="color:red;">*</span></label>
            <input type="text" appFocus autocomplete="off" minlength="1" maxlength="100" placeholder="Title"
                name="title" class="form-control input-style1 bg-grey" [(ngModel)]="milestoneInfo.milestone" #title="ngModel"
                [readonly]="diable_update_button"  (ngModelChange)="notSaved=true"
                [ngClass]="{ 'is-invalid': (f.submitted || !f.pristine) && title.invalid }" required>
            <div *ngIf="(f.submitted || !f.pristine) && title.invalid" class="invalid-feedback">
                <div *ngIf="title.errors?.required">Milestone title is required</div>
            </div>
        </div>   
        <div class="row mb-3">
            <div class="col-6">
                    <label for="targetDate">Due date</label>
                    <div class="input-group">                        
                        <input type="text" style="z-index: 500;" autocomplete="off" minlength="1" maxlength="100"
                            placeholder="Due Date" name="targetDate" class="form-control input-style1 bg-grey" [(ngModel)]="milestoneInfo.targetDate"
                            #targetDate="ngModel" [ngClass]="{ 'is-invalid': (f.submitted || !f.pristine) && targetDate.invalid }" 
                            #bsdue="bsDatepicker" bsDatepicker placement="bottom" [minDate]="targetMinDate"                    
                            [readonly]="diable_update_button"  (ngModelChange)="notSaved=true"
                            [bsValue]="milestoneInfo.targetDate"
                            [bsConfig]="{showWeekNumbers:false, dateInputFormat: dateFormat, containerClass: 'theme-dark-blue', customTodayClass:'bs-current-date'}">
                        <span class="input-group-addon input-style1 bg-grey" (click)="bsdue.toggle()" [attr.aria-expanded]="bsdue.isOpen"
                            style="padding: 10px; border-top-left-radius: 3px; border-bottom-left-radius: 3px; border-right: none; line-height: 1.8;">
                            <img class="btn-img p-1" width="30" height="30" src="assets/icons/due-date.svg">
                        </span>
                    </div>
                </div>
                <div class="col-6">
                    <label for="targetDate">Status</label>
                    <div class="input-group">
                        <mat-select  [(ngModel)]="milestoneInfo.status" name="status" class="form-control input-style1 bg-grey">
                            <mat-option value="null" selected hidden  disabled >Action status</mat-option>
                            <mat-option [value]="'new'">New</mat-option>
                            <mat-option [value]="'inprogress'">In Progress</mat-option>
                            <mat-option [value]="'completed'">Completed</mat-option>
                            <mat-option [value]="'hold'">On Hold</mat-option>
                        </mat-select>
                    </div>
                </div>
        </div>

        <div class="form-group">
            <label for="description">Description </label>
            <textarea autocomplete="off" minlength="1" maxlength="100" placeholder="Description" name="description"
            [readonly]="diable_update_button"  (ngModelChange)="notSaved=true"
                class="form-control input-style1 bg-grey" [(ngModel)]="milestoneInfo.description" #description="ngModel"> </textarea>
        </div>
        <div class="form-group mb-0 mt-3 text-center">   
            <button [disabled]="!f.form.valid || isSaving" *ngIf="! diable_update_button" type="submit" class="btn btn-save">
                Save <i *ngIf="isSaving" class="fa fa-spinner fa-spin"></i>
            </button> 
            <small class="float-right" style="color:#E74C3C">* Required</small>
        </div> 
        
    </div>
</form>