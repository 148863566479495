<form novalidate (ngSubmit)="f.form.valid && saveGoals()" #f="ngForm" >
    <div class="modal-header">
			<h4 class="title w-100 text-center">{{ modalType === 'create' ? 'New' : 'Update' }} goal</h4>
        <div class="d-flex flex-row ml-auto justify-content-end gap-1">
            <button type="button" class="close text-muted ml-auto pl-0" aria-label="Close" (click)="closeModal(f)">
                <span aria-hidden="true" >&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body modal-lg pb-0 pt-4" style="max-width: 100% !important; margin-right: 0;">
         <div class="form-group">
            <label for="title">Goal title<span style="color:red;">*</span></label>
					 <input id="title" type="text" appFocus autocomplete="off" minlength="1" placeholder="Title"
                name="title" class="form-control input-style1 bg-grey" [(ngModel)]="goalInfo.goalTitle" #title="ngModel"
                [readonly]="diable_update_button"  (ngModelChange)="notSaved=true"
                [ngClass]="{ 'is-invalid': (f.submitted || !f.pristine) && title.invalid }" required>
            <div *ngIf="(f.submitted || !f.pristine) && title.invalid" class="invalid-feedback">
                <div *ngIf="title.errors.required">Goal title is required</div>
            </div>
        </div>       
       
        <div class="d-flex flex-row gap-3">
            <div class="w-50 form-group">
                    <label for="targetDate">Due date</label>
                    <div class="input-group input-style1 bg-grey">
											<input id="targetDate" type="text" style="z-index: 500;" autocomplete="off" minlength="1"
														 maxlength="100" placeholder="Due Date" name="targetDate"
														 class="form-control input-style1 bg-grey" [(ngModel)]="goalInfo.targetDate"
                            #targetDate="ngModel" [ngClass]="{ 'is-invalid': (f.submitted || !f.pristine) && targetDate.invalid }" 
                            #bsdue="bsDatepicker" bsDatepicker placement="bottom"                    
                            [readonly]="diable_update_button"  (ngModelChange)="notSaved=true"
                            [bsValue]="goalInfo.targetDate"
                            [bsConfig]="{showWeekNumbers:false, dateInputFormat: dateFormat, containerClass: 'theme-dark-blue', customTodayClass:'bs-current-date'}">
                        <span class="input-group-addon" (click)="bsdue.toggle()" [attr.aria-expanded]="bsdue.isOpen"
                            style="padding: 10px; line-height: 1.8;">
                            <img class="btn-img p-1" width="30" height="30" src="assets/icons/due-date.svg">
                        </span>
                        
                        <!-- <div *ngIf="(f.submitted || !f.pristine) && targetDate.invalid" class="invalid-feedback">
                        <div *ngIf="targetDate.errors.required">Target Date is required</div>
                    </div> -->
                    </div>
                </div>
                <div class="w-50 form-group">
                    <!-- <label for="targetDate">Due Date<span style="color:red;">*</span></label> -->
									<label for="status">Status</label>
                    <div class="input-group">
											<ng-select labelForId="status" placeholder='Select status' [(ngModel)]="goalInfo.status"
																 name="status" class="form-control  input-style1 bg-grey">
                            <ng-option [value]="'new'">New</ng-option>
                            <ng-option [value]="'inprogress'">In Progress</ng-option>
                            <ng-option [value]="'completed'">Completed</ng-option>
                            <ng-option [value]="'hold'">On Hold</ng-option>
                        </ng-select>
                    </div>
                </div>
        </div>
        <div class="d-flex flex-row gap-3">
					<div class="w-50 form-group" *ngIf="goalInfo?.goalType === 'team'">
						<label for="teams">Select teams </label>
						<ng-select labelForId="teams" name="selectedTeams"
                [(ngModel)]="selectedTeams"  class="form-control border-0 input-style1 bg-grey" placeholder='Select teams' multiple='true'
                 (ngModelChange)="selectedTeamsChange($event)">
                    <ng-option  *ngFor="let team of teams" [value]="team">{{team.name}}</ng-option>
                </ng-select>
            </div>
					<label class="form-group {{goalInfo?.goalType === 'team' ? 'w-50' : 'w-100'}}">
						Select members
						<app-select-users
								name="members"
								inputClass="form-control border-0 input-style1 bg-grey"
								placeholder="Select members"
								labelField="name"
								[users]="users"
								[sliceUsers]="goalInfo?.goalType === 'team' ? 1:3"
								(ngModelChange)="notSaved=true"
								[(ngModel)]="selectedUsers"
						/>
					</label>
        </div>

        <div class="form-group">
					<label for="description">Description</label>
					<textarea
							id="description"
							autocomplete="off"
							minlength="1"
							placeholder="Description"
							class="form-control input-style1 bg-grey"
							name="description"
							[readonly]="diable_update_button"
							[(ngModel)]="goalInfo.description"
							(ngModelChange)="notSaved=true"
					>
						</textarea>
				</div>
         <!-- mile stones -->    
        <div class="form-group d-flex border-top border-primary" style='padding-top: 10px;'>
            <h4>Milestones</h4>
            <button (click)="addMilestone()" type="button" class="ml-auto mr-3 py-1 btn btn-outline-dark-primary">
                    <i class="fa fa-plus "></i> &nbsp; Add
            </button>
        </div>      

        <div class="d-flex flex-column gap-2 pb-2">        
            <div class="gap-2 d-flex flex-row align-items-center" *ngFor="let item of goalMilestones; let i = index">
                <input type="text" placeholder="Title"
                name="title" class="form-control input-style1 bg-grey" [(ngModel)]="item.milestone" 
                #title="ngModel" [ngModelOptions]="{standalone: true}"  (ngModelChange)="notSaved=true">
							<i class="ml-auto fa fa-check mb-1 {{item?.status === 'completed' ? 'text-success': 'text-fade'}}"
                (touchend)="toggleMilestoneStatus(item)"
                (click)="toggleMilestoneStatus(item)"></i>
                <i class="fa fa-hourglass  mb-1 px-2 {{item.targetDate? 'text-muted': 'text-fade'}}" (click)="bsdue.toggle()"
                [matTooltip]="tooltip('Due on','set due date', item?.targetDate|date: dateFormat2)"></i>                
                <i class="fa fa-trash text-danger mb-1" (click)="deletemilestone(i)"></i>
    
                <input type="text" style="z-index: 500; width: 0; opacity: 0;"
                            placeholder="Due Date" name="milestoneTargetDate{{i}}" class="form-control input-style1 bg-grey" 
                            [(ngModel)]="item.targetDate"  (ngModelChange)="notSaved=true" [ngModelOptions]="{standalone: true}"
                            #targetDate="ngModel"
                            #bsdue="bsDatepicker" bsDatepicker placement="top"
                            [bsValue]="item.targetDate"
                            [bsConfig]="{showWeekNumbers:false, dateInputFormat: dateFormat, containerClass: 'theme-dark-blue', customTodayClass:'bs-current-date'}">
            </div>
            
        </div>  



        <div class="text-center">   
            <button [disabled]="!f.form.valid || isSaving" *ngIf="! diable_update_button" type="submit" class="btn btn-save">
                Save <i *ngIf="isSaving" class="fa fa-spinner fa-spin"></i>
            </button>
            <small class="float-right" style="color:#E74C3C">* Required</small>
           <!-- <button type="button" class="btn btn-danger close-button-color" (click)="closeModal(f)">
                <i class="fa fa-close"></i> Close
            </button>-->
        </div>
        
    </div>
</form>