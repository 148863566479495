import { AppDataServices } from '@store/entity/entity-services';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalService } from '@common/global/app.global.service';
import { Constants } from '@common/global/app.global.constants';
import { UserLog } from '@common/models/userLog';
import { DatePipe } from '@angular/common'
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-milestone-create',
  templateUrl: './milestone-create.component.html',
  styleUrls: ['./milestone-create.component.scss']
})
export class MilestoneCreateComponent implements OnInit {

  public modalType;
  public modalData;

  ButtonText: string = 'Save Goal';
  scheduledMinDate:Date = new Date();
  targetMinDate:Date = new Date();
  completedMinDate = new Date();
  userLogs: UserLog = new UserLog();
  milestoneInfo: any = {}

  roles = Constants.roles;
  milestonesNumberCount = [];
  goal_Types = [];
  show_type = false;
  diable_update_button = false;
  notSaved = false;
  isSaving = false;

  public eventT: EventEmitter<any> = new EventEmitter();
  constructor(public datepipe: DatePipe,public bsModalRef: BsModalRef, public globals: GlobalService, private appDataServices: AppDataServices) {
 
  }

  ngOnInit() {

    if (this.modalType == 'create') {
      this.milestoneInfo = {
        ...this.modalData,
        "milestone": "",
        "targetDate": null,
        "completionDate": null,
        "description": "",
        "status": "new",
        "enterpriseId": this.globals.currentEnterpriseId
      };

      this.ButtonText = 'Save Goal';
      this.scheduledMinDate = new Date();
      this.targetMinDate = new Date();
      this.completedMinDate = new Date();

    } else {
      this.milestoneInfo = {
        ...this.modalData,
        "scheduledDate": (this.modalData.scheduledDate) ? new Date(this.modalData.scheduledDate) : null,
        "targetDate": (this.modalData.targetDate) ?  new Date(this.modalData.targetDate) : null,
        "completedDate": (this.modalData.completedDate) ? new Date(this.modalData.completedDate) : null,
        "goalCategoryId": this.modalData.goalCategoryId ? this.modalData.goalCategoryId : null
      };

      if(this.modalData.createdUserId != this.globals.currentUserId){
        this.diable_update_button = true;
      }
      this.scheduledMinDate = null;
      this.targetMinDate  = null;
      this.completedMinDate = null;
      this.ButtonText = 'Update Goal';
      this.milestonesNumberCount = [];
    }
  }

  get dateFormat() {
    return this.globals.dateFormat;
  }

  close(){
    this.bsModalRef.hide();
  }

  closeModal(f: NgForm){
    if(this.notSaved) {
      this.confirmCloseSave(f);
    } else {
      this.close();
    }    
  }

  confirmCloseSave(f: NgForm) {
    this.globals.showConfirm('Are you sure you want to leave without saving your changes?', true, true, 'Save Changes', 'Discard Changes', 'confirm-success', 'confirm-danger')
    .subscribe((result) => {
      if (result) {
        if(f.form.valid)
          this.saveMilestone();
      }
      else
       this.close();
    });
  }

  saveMilestone() {
    let savingMilestoneInfo={
      ...this.milestoneInfo,
      createdUserId : this.milestoneInfo.createdUserId || this.globals.currentUserId,
      modifiedUserId : this.milestoneInfo.modifiedUserId || this.globals.currentUserId,
      goalMembers : this.milestoneInfo.goalMembers || this.globals.currentUserId,
      enterpriseId : this.globals.currentEnterpriseId,
    };



    /*if (savingMilestoneInfo.scheduledDate != '' && savingMilestoneInfo.scheduledDate != null) {
      savingMilestoneInfo.scheduledDate = savingMilestoneInfo.scheduledDate.toISOString();
    }
    if (savingMilestoneInfo.targetDate != '' && savingMilestoneInfo.targetDate != null) {
      savingMilestoneInfo.targetDate = savingMilestoneInfo.targetDate.toISOString();
    }

    if (savingMilestoneInfo.completedDate != '' && savingMilestoneInfo.completedDate != null) {
      savingMilestoneInfo.completedDate = savingMilestoneInfo.completedDate.toISOString();
    }*/

    this.isSaving = true;
    if (this.modalType == 'create') {
      this.globals.showLoading();
      this.appDataServices.milestoneService.add(savingMilestoneInfo).subscribe(
        (result) => {
          this.isSaving = false;
          this.appDataServices.goalService.load().subscribe();
          this.globals.hideLoading();
          this.eventT.emit('true');
          this.bsModalRef.hide();
        }, (err) => {
          this.isSaving = false;
          this.globals.hideLoading();
        });
    } else {
      this.globals.showLoading();
      this.appDataServices.milestoneService.update(savingMilestoneInfo).subscribe(
        (result) => {
          this.isSaving = false;
          this.globals.hideLoading();
          this.appDataServices.goalService.load().subscribe();
           this.eventT.emit('true');
          this.bsModalRef.hide();
        }, (err) => {
          this.isSaving = false;
          this.globals.hideLoading();
        });

    }

  }

}
